import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { ICart, ICartItem } from 'interfaces/cart.interface';

interface CartState {
  cart: ICart | null;
  setCart: (payload: ICart | null) => void;
  setQuantity: (key: string, quantity: number) => void;
}

export const useCartStore = create<CartState>()(
  persist(
    (set) => ({
      cart: null,
      setCart: (payload: ICart | null) => set({ cart: payload }),
      setQuantity: (key: string, quantity: number) =>
        set((state) => ({
          cart: state.cart
            ? {
                ...state.cart,
                items: state.cart.items.map((item) =>
                  item.key === key ? { ...item, quantity: quantity } : item
                ),
              }
            : null,
        })),
    }),
    {
      name: 'cart-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
