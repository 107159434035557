import {
  ICart,
  ICartBillingAddress,
  ICartItem,
} from 'interfaces/cart.interface';
import {
  IUserAddress,
  UserTaxInvoiceTypeEnum,
} from 'interfaces/user.interface';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCartStore } from 'store/cart.store';
import useSWR from 'swr';
import useShop from './useShop';
import useUser from './useUser';
import { CartRepository } from 'respository/cart.repository';

export default function useCart(cartProp: ICart | null = null) {
  const { user, userPrimaryAddress, userAddresses } = useUser();

  const { shippingMethodAvailable } = useShop();

  const cart = useCartStore((state) => state.cart);
  // set cart to store
  const setCart = useCartStore((state) => state.setCart);
  const setQuantity = useCartStore((state) => state.setQuantity);

  const isInitialMount = useRef(true);

  const {
    data: cartData,
    error: cartError,
    mutate: cartMutate,
  } = useSWR<ICart>(
    user && user.accessToken
      ? {
          url: `${process.env.NEXT_PUBLIC_API_URL}/v1/public/cart`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      : null
  );

  // cart formatted
  const cartFormatted: ICart | null = useMemo(() => {
    if (cart) {
      const _cart: ICart = {
        ...cart,
        items: cart.items?.map((item) => {
          const weight = item.weight ? item.weight : 0;

          // calculate weight total and round to 2 decimal places
          const weightTotal = Math.round(weight * item.quantity);

          const _item = {
            ...item,
            weight: item.weight ? item.weight : 0,
            selected: item.selected ? item.selected : false,
            subTotal: item.price * item.quantity,
            weightTotal: weightTotal,
            maxQuantity: (() => {
              if (user?.isAuthenticated) {
                return item.maxQuantity;
              }

              if (item.variation) {
                return item.variation.stockQuantity!;
              }

              if (item.variationId && item.product.variations?.length) {
                const selectedVariation = item.product.variations.find(
                  (variation) => variation.id === item.variationId
                );
                return selectedVariation?.stockQuantity ?? 1;
              }

              if (item.product.stockQuantity) {
                return item.product.stockQuantity;
              }

              return 1;
            })(),
          };

          return _item;
        }),
        shippingMethodId: cart.shippingMethodId ? cart.shippingMethodId : 3, // default to 3 (Thai Post Regular Parcel)
      };

      // log _cart
      console.log('_cart', _cart);

      return _cart;
    }

    return null;
  }, [cart]);

  // get cart items selected
  const cartItemsSelected = useMemo(() => {
    if (cart) {
      return cart.items?.filter((item) => item.selected);
    }
    return [];
  }, [cart]);

  // get cart total quantity
  const cartTotalQuantity = cart
    ? cart.items
        ?.filter((item) => item.selected)
        .reduce((acc, item) => acc + item.quantity, 0)
    : 0;

  // get cart total weight
  const cartWeightTotal = useMemo(() => {
    if (cartFormatted) {
      const _totalWeight = cartFormatted.items
        ?.filter((item) => item.selected)
        .reduce((acc, item) => acc + item.weight * item.quantity, 0);

      // add 30% of total weight
      const _total = _totalWeight + _totalWeight * 0.3;

      // round to 2 decimal places
      return Math.round(_total);
    }
    return 0;
  }, [cartFormatted]);

  // get cart total weight without 30% added
  const cartWeightTotalWithoutAdded = useMemo(() => {
    if (cartFormatted) {
      return cartFormatted.items
        ?.filter((item) => item.selected)
        .reduce((acc, item) => acc + item.weight * item.quantity, 0);
    }
    return 0;
  }, [cartFormatted]);

  // get cart total shipping
  const cartShippingTotal = useMemo(() => {
    if (
      cartWeightTotal &&
      cartFormatted?.shippingMethodId &&
      shippingMethodAvailable
    ) {
      const shippingMethod = shippingMethodAvailable?.find(
        (method) => method.id === cartFormatted.shippingMethodId
      );

      if (shippingMethod) {
        const rates = shippingMethod.rates;

        // find the rate
        const rate = rates?.find((rate) => {
          if (rate.minWeight && rate.maxWeight) {
            return (
              cartWeightTotal >= rate.minWeight &&
              cartWeightTotal <= rate.maxWeight
            );
          } else if (rate.minWeight) {
            return cartWeightTotal >= rate.minWeight;
          } else if (rate.maxWeight) {
            return cartWeightTotal <= rate.maxWeight;
          }
          return false;
        });

        // find maxWeight
        const maxWeight = rates?.reduce((acc, rate) => {
          if (rate.maxWeight) {
            return rate.maxWeight > acc ? rate.maxWeight : acc;
          }
          return acc;
        }, 0);

        if (maxWeight) {
          const maxWeightRate = rates?.find(
            (rate) => rate.maxWeight === maxWeight
          );

          // if cartWeightTotal > maxWeight
          if (cartWeightTotal > maxWeight && maxWeightRate) {
            return maxWeightRate.finalPrice;
          }
        }

        if (rate) {
          return rate.finalPrice;
        }
      } else {
        return 0;
      }
    } else if (cartFormatted?.shippingTotal && shippingMethodAvailable) {
      return cartFormatted?.shippingTotal;
    }
  }, [cartWeightTotal, cartFormatted, shippingMethodAvailable]);

  // get cart total
  const cartTotal = useMemo(() => {
    if (cartFormatted && cartShippingTotal) {
      const _total = cartFormatted.items
        ?.filter((item) => item.selected)
        .reduce((acc, item) => acc + item.subTotal, 0);

      const totalFormatted = _total + cartShippingTotal;

      // Check if cart has discount
      // If cart has discount, return subTotal - discount
      if (cartFormatted.discountTotal > 0) {
        return totalFormatted - cartFormatted.discountTotal;
      }

      return Math.floor(totalFormatted);
    }
    return 0;
  }, [cartFormatted, cartShippingTotal]);

  // get cart sub total
  const cartSubTotal = useMemo(() => {
    if (cartFormatted) {
      const subTotal = cartFormatted.items
        ?.filter((item) => item.selected)
        .reduce((acc, item) => acc + item.subTotal, 0);

      return subTotal;
    }
    return 0;
  }, [cartFormatted]);

  // get cart items is all selected
  const cartIsAllSelected = useMemo(() => {
    if (cart) {
      return cart.items?.every((item) => item.selected);
    }
    return false;
  }, [cart]);

  // get cart items is intermediate selected
  const cartIsInterMediateSelected = useMemo(() => {
    if (cart) {
      return (
        cart.items?.some((item) => item.selected) &&
        !cart.items?.every((item) => item.selected)
      );
    }
    return false;
  }, [cart]);

  // get cart items selected count
  const cartSelectedCount = useMemo(() => {
    if (cart) {
      return cart.items?.filter((item) => item.selected).length;
    }
    return 0;
  }, [cart]);

  // get shipping address
  const cartShippingAddress: IUserAddress | null = useMemo(() => {
    if (cart?.shippingAddress) {
      return cart.shippingAddress;
    } else if (userPrimaryAddress) {
      return userPrimaryAddress;
    } else {
      if (userAddresses && userAddresses.length > 0) {
        return userAddresses[0];
      }
    }

    return null;
  }, [cart, userPrimaryAddress, userAddresses]);

  // get shipping address need billing
  const cartShippingAddressHasTaxInvoice = useMemo(() => {
    if (!cartShippingAddress) return false;

    return (cartShippingAddress.taxInvoiceType ===
      UserTaxInvoiceTypeEnum.INDIVIDUAL &&
      cartShippingAddress.taxInvoiceId) ||
      (cartShippingAddress.taxInvoiceType === UserTaxInvoiceTypeEnum.COMPANY &&
        cartShippingAddress.taxInvoiceCompanyName)
      ? true
      : false;
  }, [cartShippingAddress]);

  // get billing address
  const cartBillingAddress: ICartBillingAddress | null = useMemo(() => {
    if (cart?.billingAddress) {
      return cart.billingAddress;
    }

    return null;
  }, [cart]);

  // get cart billing address formatted
  const cartBillingAddressFormatted: string = useMemo(() => {
    if (cartBillingAddress) {
      return cartBillingAddress
        ? `${cartBillingAddress.address} ${cartBillingAddress.street} ${cartBillingAddress.subDistrict}, ${cartBillingAddress.district}, ${cartBillingAddress.province}, ${cartBillingAddress.postalCode}`
        : '';
    }

    return '';
  }, [cartBillingAddress]);

  // get cart from local storage
  /* useEffect(() => {
    if (!user?.isAuthenticated) {
      const _cart = localStorage.getItem('cart');
      if (_cart) {
        setCart(JSON.parse(_cart));
      }
    }
  }, [user]); */

  useEffect(() => {
    if (cartProp) {
      setCart({
        ...cartProp,
      });
    }
  }, [cartProp]);

  useEffect(() => {
    if (cartData) {
      setCart({
        ...cartData,
      });
    }
  }, [cartData]);

  // set cart to local storage
  const cartUpdate = (cart: ICart) => {
    // log cart for update
    console.log('cartUpdate', cart);

    setCart({ ...cart });
    // localStorage.setItem('cart', JSON.stringify(cart));
  };

  // update quantity of cart item
  const cartItemQuantityUpdate = useCallback(
    (key: string, quantity: number) => {
      if (cart) {
        setQuantity(key, quantity);
      } else {
        console.error('cart is null');
      }
    },
    [cart]
  );

  // select cart item
  const cartItemSelect = useCallback(
    (key: string, selected: boolean) => {
      if (cart) {
        const newCart = {
          ...cart,
          items: cart?.items.map((item) => {
            if (item.key === key) {
              return {
                ...item,
                selected,
              };
            }
            return item;
          }),
        };

        cartUpdate(newCart);
      }
    },
    [cart]
  );

  // select all cart items
  const cartItemSelectAll = useCallback(
    (selected: boolean) => {
      if (cart) {
        const newCart = {
          ...cart,
          items: cart?.items.map((item) => {
            return {
              ...item,
              selected,
            };
          }),
        };

        cartUpdate(newCart);
      }
    },
    [cart]
  );

  // remove cart item
  const cartItemDelete = useCallback(
    (key: string) => {
      if (cart) {
        const newCart = {
          ...cart,
          items: cart?.items?.filter((item) => item.key !== key),
        };

        // if cart items is empty, remove cart from local storage
        if (newCart.items.length === 0) {
          cartClear();
        } else {
          cartUpdate(newCart);
        }
      }
    },
    [cart]
  );

  // cart set shipping method
  const cartSetShippingMethod = useCallback(
    (shippingMethodId: number) => {
      if (cart) {
        const newCart = {
          ...cart,
          shippingMethodId: shippingMethodId,
        };

        cartUpdate(newCart);
      }
    },
    [cart]
  );

  // remove cart
  const cartClear = () => {
    localStorage.removeItem('cart');
    setCart(null);
  };

  const updateCartItemsToServer = async (
    cartItemsNotInCartFormatted: ICartItem[]
  ) => {
    try {
      for (const item of cartItemsNotInCartFormatted) {
        // log item
        console.log('cartItemsNotInCartFormatteditem', item);
        await CartRepository.addOrUpdateItemToCart({
          key: item.key,
          productId: item.product.id,
          variationId: item.variationId ?? undefined,
          quantity: item.quantity,
          attributeOptionIds:
            item.variationId && item.attributeOptions
              ? item.attributeOptions?.map((option) => option.id)
              : [],
        });
      }
    } catch (error) {
      console.error('Error updating cart items to server:', error);
      // You might want to handle the error here, e.g., show a notification to the user
    }
  };

  // compare cart between local storage and server if user is authenticated
  // if cart is different item, update cart to server
  useEffect(() => {
    if (user?.isAuthenticated && cartData && cart) {
      if (cartData) {
        // find cart item that is not in cartFormatted
        const cartItemsNotInCartFormatted = cart?.items.filter(
          (item) => !cartData.items.some((x) => x.key === item.key)
        );

        // if cart items not in cartFormatted, update cart to server
        if (
          cartItemsNotInCartFormatted &&
          cartItemsNotInCartFormatted.length > 0
        ) {
          updateCartItemsToServer(cartItemsNotInCartFormatted);
        }
      }
    }
  }, [user, cart, cartData]);

  return {
    cart: cartFormatted,
    cartIsLoading: !cartError && !cartData,
    cartItemsSelected,
    cartTotalQuantity,
    cartIsAllSelected,
    cartIsInterMediateSelected,
    cartSelectedCount,
    cartTotal,
    cartSubTotal,
    cartWeightTotal,
    cartWeightTotalWithoutAdded,
    cartShippingTotal,
    cartShippingAddress,
    cartShippingAddressHasTaxInvoice,
    cartBillingAddress,
    cartBillingAddressFormatted,
    cartMutate,
    cartUpdate,
    cartItemQuantityUpdate,
    cartClear,
    cartItemSelect,
    cartItemSelectAll,
    cartItemDelete,
    cartSetShippingMethod,
  };
}
