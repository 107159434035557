import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import UtilPriceFormatter from 'components/utils/UtilPriceFormatter';
import useCart from 'hooks/useCart';
import NextLink from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MiniCartItem from './MiniCartItem';

function MiniCart() {
  // use i18n
  const { t } = useTranslation(['common']);

  // get cart
  const { cart, cartSubTotal } = useCart();

  return (
    <Box px={3} py={3}>
      {cart ? (
        <>
          <Stack spacing={4} mb={4}>
            {cart.items.map((item, index) => (
              <MiniCartItem key={index} item={item}></MiniCartItem>
            ))}
          </Stack>

          <Box borderBottom="1px solid #eee" mb={3}></Box>

          <Box textAlign="center" mb={4}>
            <HStack justifyContent="space-between">
              <Text>
                {t('miniCart.subTotal', {
                  // TH: ยอดรวม
                  // EN: Subtotal
                })}
              </Text>
              <Text color="gray.800" fontWeight={400} fontSize="lg">
                <UtilPriceFormatter price={cartSubTotal}></UtilPriceFormatter>
              </Text>
            </HStack>
          </Box>
          <Box>
            <NextLink href="/cart" passHref legacyBehavior>
              <Button
                fontWeight={300}
                fontSize="20px"
                borderRadius="5px"
                width="100%"
                as="a"
              >
                {t('miniCart.action.viewCart', {
                  // TH: ดูสินค้าในตะกร้า
                  // EN: View cart
                })}
              </Button>
            </NextLink>
          </Box>
        </>
      ) : (
        <Box>
          <Text>
            {t('miniCart.empty', {
              // TH: ไม่มีสินค้าในตะกร้า
              // EN: Your cart is empty
            })}
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default MiniCart;
