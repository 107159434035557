import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// Create axios instance with default config
const _axios: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

// Set default headers
_axios.defaults.headers.common.Authorization = '';

// Map to store pending requests
const pendingRequests = new Map<string, AbortController>();

// Request interceptor
_axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['x-custom-lang'] = 'th';
    }
    console.debug(
      `AxiosRequest ${config.method?.toUpperCase()}-${config.url}`,
      config.data
    );

    const requestIdentifier = `${config.url}_${config.method}`;

    // Cancel previous request with the same identifier
    if (pendingRequests.has(requestIdentifier)) {
      pendingRequests.get(requestIdentifier)?.abort();
    }

    // Create new AbortController for this request
    const controller = new AbortController();
    config.signal = controller.signal;
    pendingRequests.set(requestIdentifier, controller);

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
_axios.interceptors.response.use(
  (response: AxiosResponse) => {
    const { config, data } = response;
    console.log(
      `AxiosResponse ${config.method?.toUpperCase()}-${
        config.url
      } ${new Date().toLocaleTimeString()}`,
      data
    );

    // Remove completed request from pending map
    const requestIdentifier = `${config.url}_${config.method}`;
    pendingRequests.delete(requestIdentifier);

    return response;
  },
  (error) => {
    // Remove failed request from pending map
    if (error.config) {
      const requestIdentifier = `${error.config.url}_${error.config.method}`;
      pendingRequests.delete(requestIdentifier);
    }
    return Promise.reject(error);
  }
);

export default _axios;
